import React from 'react';
import Layout from "../../layout/Layout";
import SEO from "../../components/seo";

import TemplateLegalPage from '../../blocks/templateLegalPage';

import RectangleLink from "../../components/RectangleLink";
import Footnote from "../../components/Footnote";

import enMessages from '../../i18n/legal/crsCompliance/en.js'
import ruMessages from '../../i18n/legal/crsCompliance/ru.js'

import enMainMessages from '../../i18n/LegalInformation/en.js'
import ruMainMessages from '../../i18n/LegalInformation/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const messagesMain = {
    en: enMainMessages,
    ru: ruMainMessages
}

const CrsCompliancePage = ({ pathContext: { locale } }) => {
    const section = 'crsCompliance';

    return (
        <TemplateLegalPage
            locale={locale}
            section={section}
            messages={messages[locale]}
            messagesMain={messagesMain[locale]}
        >
            <div>
                <div className="LegalInformation-Text" dangerouslySetInnerHTML={{ __html:messages[locale].content.information }}/>

                <div className="LegalInformation-Text">
                    <Footnote content={messages[locale].content.footnote}/>
                </div>

                <div className="LegalInformation-Text" dangerouslySetInnerHTML={{ __html: messages[locale].content.secondInformation }}/>

                {messages[locale].content.informationLink.map((item, index) => {
                    return <RectangleLink
                        key={index}
                        title={item.title}
                        link={item.link}
                    />
                })}

                <div className="Line-bottom"/>

                <div className="LegalInformation-title">{messages[locale].content.legalEntities}</div>
                <div className="LegalInformation-Text" dangerouslySetInnerHTML={{__html: messages[locale].content.legalEntitiesDescription}}/>
            </div>

        </TemplateLegalPage>
    )
};

export default CrsCompliancePage;
