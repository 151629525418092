import pdProtection from '../../../assets/files/ru/personal_data_processing_policy.pdf';

export default {
    seo: {
      title: 'Соответствие CRS | Банк 131',
      description: 'Соответствие CRS'
    },
    title: 'Соответствие CRS',
    content: {
         information: '<span class="bold">CRS. (Common Reporting Standard)</span> — Стандарт по автоматическому обмену информацией ' +
             'о финансовых счетах, разработанный ОЭСР (Организацией экономического сотрудничества ' +
             'и развития).<br/>' +
             '<br/>' +
             '<span class="bold">CRS</span> — это международный аналог FATCA, направленный на увеличение налоговой прозрачности ' +
             'и предотвращение глобального уклонения от уплаты налогов.<br/>' +
             '<br/>' +
             'Российская Федерация в мае 2016 года подтвердила свое участие в выполнении требований CRS. Согласно ' +
             'Федеральному закону от 27.11.2017 N 340-ФЗ «О внесении изменений в часть первую Налогового кодекса ' +
             'РФ в связи с реализацией международного автоматического обмена информацией и документацией по ' +
             'международным группам компаний» <span class="bold">лица, заключающие (заключившие) с Банком договор, предусматривающий ' +
             'оказание финансовых услуг обязаны предоставлять в Банк информацию, позволяющую установить налоговое ' +
             'резидентство, в отношении самих себя, выгодоприобретателей и (или) лиц, прямо или косвенно их контролирующих.</span><br/>' +
             '<br/>' +
             'Налоговый кодекс РФ содержит положения, согласно которым российские банки обязаны ' +
             'идентифицировать/ выявлять клиентов, выгодоприобретателей и (или) лиц, прямо или косвенно ' +
             'их контролирующих, являющихся налоговыми резидентами иностранных государств (территорий), ' +
             'и представлять данные о них и их счетах в ФНС России.<br/>' +
             '<br/>' +
             'Клиенты, в свою очередь, обязаны в соответствии с Налоговым кодексом РФ предоставлять запрашиваемую ' +
             'Банком информацию о налоговом резидентстве указанных лиц.<br/>' +
             '<br/>' +
             'В случае непредставления вышеуказанной информации при заключении с Банком договоров, предусматривающих ' +
             'оказание финансовых услуг, Банк вправе:',
         footnote: [
             {
                 text: 'отказать в заключении такого договора;',
                 numbering: '—'
             },
             {
                 text: 'отказать в совершении операций, осуществляемых в пользу или по поручению клиента по ' +
                     'договору, предусматривающему оказание финансовых услуг;',
                 numbering: '—'
             },
             {
                 text: 'расторгнуть в одностороннем порядке договор, предусматривающий оказание финансовых услуг, ' +
                     'уведомив об этом клиента не позднее одного рабочего дня, следующего за днем принятия решения.',
                 numbering: '—'
             },
         ],
         secondInformation: 'Банк вправе в одностороннем порядке расторгнуть заключенный с клиентом договор, предусматривающий оказание финансовых услуг, если в результате проведения мер, предусмотренных пунктом 1 статьи 142.4 Главы 20.1 НК РФ, Банк выявит недостоверность \n' +
             'или неполноту представленной клиентом информации либо придет к выводу о противоречии представленной клиентом информации сведениям, имеющимся в распоряжении Банка, ' +
             'в том числе полученным из иных общедоступных источников информации.<br/>' +
             '<br/>' +
             'Для целей исполнения Российского законодательства АО «Банк 131» должен установить является ли клиент, его выгодоприобретатель и (или) лица, прямо или косвенно их контролирующие «налоговым резидентом» с указанием страны налогового резидентства. АО «Банк 131» имеет возможность определить статус «налогового резидента» Клиента на основании уже имеющейся \n' +
             'в АО «Банк 131» информации, но также могут быть запрошены для заполнения и направления \n' +
             'в АО «Банк 131» соответствующие Формы подтверждения статуса налогового резидента.<br/>' +
             '<br/>' +
             'АО «Банк 131» не оказывает консультационные услуги по вопросам определения налогового резидентства согласно требованиям CRS. По данному вопросу можно ознакомиться \n' +
             'с информацией по налоговому резидентству для разных стран на портале \n' +
             'Организации экономического сотрудничества и развития по адресу:<br/> <a href="http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-residency/#d.en.347760" target="_blank">http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-residency/#d.en.347760</a><br/>' +
             '<br/>' +
             'Для подтверждения налогового резидентства работник АО «Банк 131» вправе задать уточняющие вопросы, а также попросить заполнить форму самосертификации:',
         informationLink: [
             {
                 title: 'Форма подтверждения статуса налогового резидента для физического лица (в том числе лица, занимающегося частной практикой, и индивидуального предпринимателя)',
                 link: '/legal/ru/PERSONAL_SELF-CERTIFICATION_FORM(CRS).doc'
             },
             {
                 title: 'Форма подтверждения статуса налогового резидента (для юридического лица, \n' +
                     'в том числе иностранной организации без образования юридического лица).',
                 link: '/legal/ru/SELF-CERTIFICATION_FORM_OF_LEGAL-ENTITY(CRS).doc'
             },
         ],
         legalEntities: 'Информация для клиентов — юридических лиц',
         legalEntitiesDescription: 'О структуре формирования ИНН (TIN) доступна по ссылке на сайт ОЭСР — <a href="http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers" target="_blank">http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers</a><br/>' +
             '<br/>' +
             'Касательно налогового резидентства в иностранных государствах доступна по ссылке на сайт ОЭСР — <a href="http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-residency/#d.en.347760" target="_blank">http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-residency/#d.en.347760</a><br/>' +
             '<br/>' +
             'Список государств, предоставляющий инвестиционное резидентство размещен на сайте ОЭСР — <a href="http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/residence-citizenship-by-investment/" target="_blank">http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/residence-citizenship-by-investment/</a><br/>' +
             '<br/>' +
             'ФНС России открыл на официальном сайте раздел, посвященный международному автоматическому обмену информацией, где публикуются документы и часто задаваемые вопросы: <a href="https://340fzreport.nalog.ru/" target="_blank">https://340fzreport.nalog.ru/</a>'
     }
}