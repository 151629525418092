

export default {
    seo: {
        title: 'CRS Compliance | Bank 131',
        description: 'CRS Compliance'
    },
    title: 'CRS Compliance',
    content: {
         information: '<span class="bold">Common Reporting Standard (CRS)</span> is an automated financial account ' +
             'data exchange standard developed by the Organisation for Economic Co-operation and Development.<br/>' +
             '<br/>' +
             '<span class="bold">CRS</span> is an international analog of FATCA aimed at improving taxation transparency and preventing global tax avoidance.<br/>' +
             '<br/>' +
             'In May 2016, Russia confirmed its engagement in CRS compliance. Per Federal Law of Russia No. ' +
             '340-FZ dd. November 27, 2017 "On amendments to Part 1 of the Tax Code of Russia due to the ' +
             'international automated data and document exchange regarding international groups", persons ' +
             'concluding (or those having concluded) a financial service agreement with the Bank are obliged ' +
             'to provide the Bank with tax residence information regarding themselves, their beneficiaries, ' +
             'and/or persons directly or indirectly controlling thereof.<br/>' +
             '<br/>' +
             'The Tax Code of Russia contains some provisions according to which Russian banks are obliged to ' +
             'identify the customers, beneficiaries, and/or persons directly or indirectly controlling thereof, ' +
             'who are tax residents of foreign countries (jurisdictions), and provide information on them and ' +
             'their accounts to the Federal Tax Service of Russia.<br/>' +
             '<br/>' +
             'In compliance with the Tax Code of Russia, customers are obliged to provide tax residence information ' +
             'regarding the aforementioned persons, as required by the Bank.<br/>' +
             '<br/>' +
             'If the customer fails to provide the aforementioned information when concluding a ' +
             'financial service agreement with the Bank, the Bank shall be entitled to:',
         footnote: [
             {
                 text: 'decline such an agreement',
                 numbering: '—'
             },
             {
                 text: 'refuse to execute transactions on behalf or by order of the customer under the financial service agreement',
                 numbering: '—'
             },
             {
                 text: 'unilaterally terminate the financial service agreement having notified the customer of such an event no later than in one business day following the day of making such a decision',
                 numbering: '—'
             },
         ],
         secondInformation: 'The Bank is entitled to unilaterally terminate the financial service agreement ' +
             'concluded with the Customer if the Bank—when taking measures as prescribed in Clause 1 of ' +
             'Article 142.4 of Chapter 20.1 of the Tax Code of Russia—identifies unreliability or ' +
             'incompleteness of information provided by the customer or finds a conflict between ' +
             'information provided by the customer and information available to the Bank (including ' +
             'information from public sources).<br/>' +
             '<br/>' +
             'For compliance with Russian law, Bank 131 JSC shall find out whether the customer, their beneficiary, ' +
             'and/or persons directly or indirectly controlling thereof, are a tax resident (with specification of ' +
             'the country of tax residence). Bank 131 JSC can identify the status of a tax resident based on ' +
             'information available to Bank 131 JSC. But, the Bank is entitled to require the customer to fill ' +
             'and provide the appropriate tax residence confirmation Forms.<br/>' +
             '<br/>' +
             'Bank 131 JSC does not consult on tax residence identification per CRS requirements. To find ' +
             'information on this matter, you can read tax residence information on the website of the ' +
             'Organisation for Economic Co-operation and Development:<br/> <a href="http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-residency/#d.en.347760" target="_blank">http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-residency/#d.en.347760</a><br/>' +
             '<br/>' +
             'To confirm the tax residence, the Bank\'s employee is entitled to ask specification questions and ask the customer to fill out the Self-Certification form:',
         informationLink: [
             {
                 title: 'TAXPAYER STATUS SELF-CERTIFICATION FORM FOR INDIVIDUALS, PRIVATE-PRACTICE PROFESSIONALS, AND SELF-EMPLOYED',
                 link: '/legal/en/PERSONAL_SELF-CERTIFICATION_FORM(CRS)(en).doc'
             },
             {
                 title: 'TAXPAYER STATUS SELF-CERTIFICATION FORM (FOR LEGAL ENTITIES INCLUDING UNINCORPORATED FOREIGN ENTITIES)',
                 link: '/legal/en/SELF-CERTIFICATION_FORM_OF_LEGAL-ENTITY(CRS)(en).doc'
             },
         ],
         legalEntities: 'Supplementary Information',
         legalEntitiesDescription: 'TIN: <a href="http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers" target="_blank">http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers</a><br/>' +
             '<br/>' +
             'Tax residence in foreign countries: <a href="http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-residency/#d.en.347760" target="_blank">http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-residency/#d.en.347760</a><br/>' +
             '<br/>' +
             'Countries providing investment residence:  <a href="http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/residence-citizenship-by-investment/" target="_blank">http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/residence-citizenship-by-investment/</a><br/>' +
             '<br/>' +
             'The Tax Service of Russia opened a section of the corporate website, devoted to the international automated information exchange, where documents and frequently asked questions are published:  <a href="https://340fzreport.nalog.ru/" target="_blank">https://340fzreport.nalog.ru/</a>'
     }
}